import BigNumber from "bignumber.js";
import { CarbonTx, Insights } from "carbon-js-sdk";
import { SimpleMap } from "carbon-js-sdk/lib/util/type";
import { ActiveUsersChartDataResponse, TotalUsersChartDataResponse } from "js/reducers/dashboard";
import { bnOrZero } from "js/utils";
import { DailyLiquiditySum } from "js/utils/typing";
import moment, { Moment } from "moment";

export const formatStakingChartData = (dataRaw: any) => {
  const labels: any[] = [];
  const data: any = {
    staked: []
  };
  dataRaw?.forEach((entries: any) => {
    data.staked.push({
      x: moment(entries.date).utc(),
      y: new BigNumber(entries.total).shiftedBy(-8).toNumber()
    })
    labels.push(moment(entries.date).utc())
  })
  return [labels, data]
}

export const formatInterestRateModelChartData = (dataRaw: any) => {
  const labels: any[] = [];
  const data: any = [];
  dataRaw?.forEach((entries: any) => {
    data.push({
      x: bnOrZero(entries.utilizationRate).toNumber(),
      y: new BigNumber(entries.interestRate).toNumber()
    })
    labels.push(`${entries.utilizationRate}%`)
  })
  return [labels, data]
}

export const formatTxChartData = (dataRaw: any) => {
  const labels: any[] = [];
  const data: any = {
    staked: []
  };
  dataRaw?.forEach((entries: any) => {
    data.staked.push({
      x: moment(entries.date).utc(),
      y: parseInt(entries.txCount)
    })
    labels.push(moment(entries.date).utc())
  })
  return [labels, data]
}

export const formatActiveUsersChartData = (dataRaw: ActiveUsersChartDataResponse) => {
  const activeAccountsDataRaw = dataRaw.activeAccountsChartData
  const userGrowthDataRaw = dataRaw.userGrowthChartData
  const labels: any[] = [];
  const data: any = {
    returningUsers: [],
    newUsers: [],
    totalUsers: [],
  }

  activeAccountsDataRaw?.forEach((entries: any) => {
    data.totalUsers.push({
      x: moment(entries.date).utc(),
      y: parseInt(entries.last1DCount)
    })
    labels.push(moment(entries.date).utc())
  })

  userGrowthDataRaw?.forEach((entries: any) => {
    data.newUsers.push({
      x: moment(entries.t).utc(),
      y: parseInt(entries.users)
    })
  })

  data.totalUsers?.forEach((entries: any) => {
    data.returningUsers.push({
      x: entries.x,
      y: entries.y - (data.newUsers.find((item: any) => entries.x.toString() === item.x.toString())?.y ?? 0)
    })
  })
  const dataList = [data.returningUsers, data.newUsers]
  dataList.forEach((entries: any) => {
    if (labels.length !== entries.length) {
      labels.forEach((label: any) => {
        if (!entries.find((item: any) => item.x.toString() === label.toString())) {
          entries.push({
            x: label,
            y: 0
          })
        }
      })
    }
  })
  data.newUsers = data.newUsers.sort((a: { x: any }, b: { x: any }) => a.x.unix() - b.x.unix())
  return [labels, data]
}

export const formatTotalUsersChartData = (dataRaw: TotalUsersChartDataResponse) => {
  const totalUserDataRaw = dataRaw.totalUsersChartData
  const activeAccountsDataRaw = dataRaw.activeAccountsChartData
  const labels: any[] = [];
  const data: any = {
    totalUsers: [],
    monthlyActiveUsers: [],
    weeklyActiveUsers: [],
    dailyActiveUsers: []
  };
  var totalUsersArraySize = 0;
  if (totalUserDataRaw) {
    totalUsersArraySize = totalUserDataRaw.length;
  }
  var totalUsersArrayIterator = 0;
  var currentTotalUser = 0;
  activeAccountsDataRaw?.forEach((entries: any) => {
    const x = moment(entries.date).utc()
    if (totalUsersArrayIterator < totalUsersArraySize && entries.date === totalUserDataRaw[totalUsersArrayIterator].date) {
      currentTotalUser = totalUserDataRaw[totalUsersArrayIterator].total;
      totalUsersArrayIterator++;
    }

    data.totalUsers.push({
      x,
      y: currentTotalUser
    })
    data.monthlyActiveUsers.push({
      x,
      y: entries.last1MCount
    })

    data.weeklyActiveUsers.push({
      x,
      y: entries.last1WCount
    })

    data.dailyActiveUsers.push({
      x,
      y: entries.last1DCount
    })
  })
  if (!activeAccountsDataRaw || !activeAccountsDataRaw?.length) {
    totalUserDataRaw.forEach((o) => {
      const x = moment(o.date).utc()
      data.totalUsers.push({
        x,
        y: o.total
      })
    })
  }
  return [labels, data]
}

export const formatMessageChartData = (dataRaw: any) => {
  const labels: any[] = [];
  const data: any = {
    txCount: [],
    txTotal: [],
    totalMsgCount: []
  };
  const requiredTxTypes = [
    CarbonTx.Types.MsgCreateOrder,
    CarbonTx.Types.MsgDeposit,
    CarbonTx.Types.MsgDelegate,
    CarbonTx.Types.MsgAddLiquidity,
    CarbonTx.Types.MsgStakePoolToken,
    CarbonTx.Types.MsgVote,
  ]
  const msgCountMap: SimpleMap<any[]> = {}

  dataRaw?.forEach((entries: any) => {
    const x = moment(entries.date).utc()
    data.txCount.push({
      x,
      y: parseInt(entries.txCount)
    })
    data.txTotal.push({
      x,
      y: parseInt(entries.txTotal)
    })
    let totalMsgCount = 0
    entries.txEvent.forEach((txEvent: any) => {
      totalMsgCount += txEvent.count
      if ((txEvent.type in msgCountMap) === false) {
        msgCountMap[txEvent.type] = []
      }
      msgCountMap[txEvent.type].push({
        x,
        y: txEvent.count
      })
    })
    data.totalMsgCount.push({
      x,
      y: totalMsgCount
    })
    labels.push(moment(entries.date).utc())
  })

  // 10% of the query result
  const compareDataLength = Math.ceil(dataRaw.length * 0.1)
  const sorted = Object.entries(msgCountMap)
    .sort(([keyA, valueA]: any, [keyB, valueB]: any) => {
      const sumA = valueA.slice(Math.max(valueA.length - compareDataLength, 0)).reduce((sum: number, item: any) => sum + item.y, 0)
      const sumB = valueB.slice(Math.max(valueB.length - compareDataLength, 0)).reduce((sum: number, item: any) => sum + item.y, 0)
      return sumA - sumB
    })
  const TX_MSG_RESULT_NUMBER = 10
  const topSorted = sorted.slice(sorted.length - TX_MSG_RESULT_NUMBER, sorted.length)
  const topSortedList = Object.values(topSorted).map((value: any) => value[0])
  const requiredList = Object.entries(msgCountMap).filter(([key, _]: any) => {
    const removeIntersectedList = requiredTxTypes.filter((item: any) => !topSortedList.includes(item))
    return removeIntersectedList.includes(key)
  })
  const appendedList = topSorted.concat(requiredList)
  appendedList.map(([key, value]: any) => {
    if (value.length !== labels.length) {
      labels.forEach((label: any) => {
        if (!value.find((item: any) => item.x.toString() === label.toString())) {
          value.push({
            x: label,
            y: 0
          })
        }
      })
    }
    value.sort((a: any, b: any) => a.x - b.x)
    return [key, value]
  })
  let otherCountArray: any[] = []
  data.totalMsgCount.forEach((item: any, index: number) => {
    let remainingCount = item.y
    appendedList.forEach(([_, value]: any) => {
      remainingCount -= value[index]?.y ?? 0
    })
    otherCountArray.push({
      x: item.x,
      y: remainingCount
    })
  })

  const arrangedFinal = Object.values(appendedList).sort(([keyA, valueA]: any, [keyB, valueB]: any) => {
    const sumA = valueA.slice(Math.max(valueA.length - compareDataLength, 0)).reduce((sum: number, item: any) => sum + item.y, 0)
    const sumB = valueB.slice(Math.max(valueB.length - compareDataLength, 0)).reduce((sum: number, item: any) => sum + item.y, 0)
    return sumA - sumB
  })

  const otherCount = ['Others', otherCountArray] as any
  arrangedFinal.push(otherCount)

  return [labels, arrangedFinal]
}

export const formatMarketVolumeChartData = (dataRaw: any) => {
  const labels: any[] = [];
  const data: any = {
    volumeValue: [],
    totalVolumeValue: [],
  };

  const marketsVolumeMap: SimpleMap<any[]> = {}

  dataRaw?.forEach((entries: any) => {
    const x = moment(entries.date).utc()
    data.volumeValue.push({
      x,
      y: parseInt(entries.volumeValue)
    })
    data.totalVolumeValue.push({
      x,
      y: parseInt(entries.totalVolumeValue)
    })
    entries.markets.forEach((marketVolume: any) => {
      if ((marketVolume.market in marketsVolumeMap) === false) {
        marketsVolumeMap[marketVolume.market] = []
      }
      marketsVolumeMap[marketVolume.market].push({
        x,
        y: marketVolume.volumeValue
      })
    })
    labels.push(moment(entries.date).utc())
  })

  // 10% of the query result
  const compareDataLength = Math.ceil(dataRaw.length * 0.1)
  const sorted = Object.entries(marketsVolumeMap)
    .sort(([keyA, valueA]: any, [keyB, valueB]: any) => {
      const sumA = valueA.slice(Math.max(valueA.length - compareDataLength, 0)).reduce((sum: number, item: any) => sum + item.y, 0)
      const sumB = valueB.slice(Math.max(valueB.length - compareDataLength, 0)).reduce((sum: number, item: any) => sum + item.y, 0)
      return sumA - sumB
    })
  const MARKETS_TO_SHOW = 13

  let finalSorted = sorted
  if (sorted.length > MARKETS_TO_SHOW) {
    const topSorted = sorted.slice(sorted.length - MARKETS_TO_SHOW, sorted.length)
    sorted.map(([key, value]: any) => {
      if (value.length !== labels.length) {
        labels.forEach((label: any) => {
          if (!value.find((item: any) => item.x.toString() === label.toString())) {
            value.push({
              x: label,
              y: 0
            })
          }
        })
      }
      value.sort((a: any, b: any) => a.x - b.x)
      return [key, value]
    })
    let otherVolumeArray: any[] = []
    data.volumeValue.forEach((item: any, index: number) => {
      let remainingCount = item.y
      topSorted.forEach(([_, value]: any) => {
        remainingCount -= value[index]?.y ?? 0
      })
      otherVolumeArray.push({
        x: item.x,
        y: remainingCount
      })
    })

    const otherCount = ['Other Markets', otherVolumeArray] as any
    topSorted.push(otherCount)
    finalSorted = topSorted
  }
  return [labels, finalSorted]
}

export const formatDistributionRewardsChartData = (dataRaw: any) => {
  const labels: any[] = [];
  const data: any = {
    amountValue: [],
    totalamountValue: [],
    sumAmountValue: [],
  };

  const denomMap: SimpleMap<any[]> = {}

  dataRaw?.forEach((entries: any) => {
    const x = moment(entries.date).utc()
    data.amountValue.push({
      x,
      y: parseInt(entries.amountValue)
    })
    data.totalamountValue.push({
      x,
      y: parseInt(entries.totalamountValue)
    })
    let sumValue = 0
    let sumShifted = 0
    entries.tokens.forEach((marketDistribution: any) => {
      if ((marketDistribution.denom in denomMap) === false) {
        denomMap[marketDistribution.denom] = []
      }
      denomMap[marketDistribution.denom].push({
        x,
        y: marketDistribution.amountValue,
        z: marketDistribution.amountShifted
      })
      sumValue += marketDistribution.amountValue
    })
    data.sumAmountValue.push({
      x,
      y: sumValue,
      z: sumShifted
    })
    labels.push(moment(entries.date).utc())
  })

  // 10% of the query result
  const compareDataLength = Math.ceil(dataRaw.length * 0.1)
  const sorted = Object.entries(denomMap)
    .sort(([keyA, valueA]: any, [keyB, valueB]: any) => {
      const sumA = valueA.slice(Math.max(valueA.length - compareDataLength, 0)).reduce((sum: number, item: any) => sum + item.y, 0)
      const sumB = valueB.slice(Math.max(valueB.length - compareDataLength, 0)).reduce((sum: number, item: any) => sum + item.y, 0)
      return sumA - sumB
    })
  const MARKETS_TO_SHOW = 13

  let finalSorted = sorted
  if (sorted.length > MARKETS_TO_SHOW) {
    const topSorted = sorted.slice(sorted.length - MARKETS_TO_SHOW, sorted.length)
    sorted.map(([key, value]: any) => {
      if (value.length !== labels.length) {
        labels.forEach((label: any) => {
          if (!value.find((item: any) => item.x.toString() === label.toString())) {
            value.push({
              x: label,
              y: 0,
              z: 0
            })
          }
        })
      }
      value.sort((a: any, b: any) => a.x - b.x)
      return [key, value]
    })
    let otherDistributionArray: any[] = []
    data.sumAmountValue.forEach((item: any, index: number) => {
      let remainingValue = item.y
      let remainingShifted = item.z
      topSorted.forEach(([_, value]: any) => {
        remainingValue -= value[index]?.y ?? 0
      })
      otherDistributionArray.push({
        x: item.x,
        y: remainingValue < 0 ? remainingValue * -1 : remainingValue,
        z: remainingShifted < 0 ? remainingShifted * -1 : remainingShifted,
      })
    })

    const otherCount = ['Others', otherDistributionArray] as any
    topSorted.push(otherCount)
    finalSorted = topSorted
  }
  return [labels, finalSorted]
}

export const computeTotalLiquidityDailySums = (dataRaw: any) => {
  const dailySums: DailyLiquiditySum[] = [];
  dataRaw?.forEach((entries: any) => {
    const x = moment(entries.date).utc()
    let dailySum = 0;
    entries.pools.forEach(
      (liquidityPools: any) => {
        dailySum += liquidityPools.amountValue
      }
    )
    dailySums.push({
      x,
      y: dailySum
    })
  })
  return dailySums;
}

export const formatTotalLiquidityChartData = (dataRaw: any) => {
  const labels: any[] = [];

  const dailySums: DailyLiquiditySum[] = [];
  const marketsLiquidityMap: SimpleMap<any[]> = {}

  dataRaw?.forEach((entries: any) => {
    const x = moment(entries.date).utc()
    let dailySum = 0;
    entries.pools.forEach(
      (liquidityPools: any) => {
        if ((liquidityPools.poolId in marketsLiquidityMap) === false) {
          marketsLiquidityMap[liquidityPools.poolId] = []
        }
        marketsLiquidityMap[liquidityPools.poolId].push({
          x,
          y: liquidityPools.amountValue
        })
        dailySum += liquidityPools.amountValue
      }
    )
    dailySums.push({
      x,
      y: dailySum
    })

    labels.push(moment(entries.date).utc())
  })

  const compareDataLength = Math.ceil(dataRaw.length * 0.1)

  // 10% of the query result

  const sorted = Object.entries(marketsLiquidityMap).sort(([keyA, valueA]: any, [keyB, valueB]: any) => {
    const sumA = valueA.slice(Math.max(valueA.length - compareDataLength, 0)).reduce((sum: number, item: any) => sum + item.y, 0)
    const sumB = valueB.slice(Math.max(valueB.length - compareDataLength, 0)).reduce((sum: number, item: any) => sum + item.y, 0)
    return sumA - sumB
  })

  const MARKETS_TO_SHOW = 13

  let finalSorted = sorted
  if (sorted.length > MARKETS_TO_SHOW) {
    const topSorted = sorted.slice(sorted.length - MARKETS_TO_SHOW, sorted.length)
    sorted.map(([key, value]: any) => {
      if (value.length !== labels.length) {
        labels.forEach((label: any) => {
          if (!value.find((item: any) => item.x.toString() === label.toString())) {
            value.push({
              x: label,
              y: 0
            })
          }
        })
      }
      value.sort((a: any, b: any) => a.x - b.x)
      return [key, value]
    })
    let otherVolumeArray: any[] = []
    dailySums.forEach((item: any, index: number) => {
      let remainingCount = item.y
      topSorted.forEach(([_, value]: any) => {
        remainingCount -= value[index]?.y ?? 0
      })
      otherVolumeArray.push({
        x: item.x,
        y: remainingCount
      })
    })

    const otherCount = ['Others', otherVolumeArray] as any
    topSorted.push(otherCount)
    finalSorted = topSorted
  }
  let totalLiquidityResult = {
    labels: labels,
    data: finalSorted,
    dailySumsResult: dailySums
  }

  return totalLiquidityResult;
}

export const formatVolumeLiquidityChartData = (dataRaw: any) => {
  const totalVolumeDataRaw = dataRaw
  const labels: any[] = [];
  const data: any = {
    totalVolume: [],
  }

  totalVolumeDataRaw?.forEach((entries: any) => {
    data.totalVolume.push({
      x: moment(entries.date).utc(),
      y: parseFloat(entries.volumeValue)
    })
    labels.push(moment(entries.date).utc())
  })

  return [labels, data]
}

export const formatUtilisationChartData = (volumeData: any, liquidityData: any) => {
  const totalVolumeDataRaw = volumeData
  const totalLiquidityData = computeTotalLiquidityDailySums(liquidityData)

  const labels: any[] = [];
  const data: any = {
    utilisationRate: []
  };

  totalVolumeDataRaw?.forEach((entries: any) => {
    data.utilisationRate.push({
      x: moment(entries.date).utc(),
      y: parseFloat(entries.volumeValue)
    })
    labels.push(moment(entries.date).utc())
  })
  if (data.utilisationRate.length !== 0 && totalLiquidityData.length === data.utilisationRate.length) {
    totalLiquidityData?.forEach((entries: any, index: number) => {
      data.utilisationRate[index].y = !data.utilisationRate[index]?.y ? 0 : data.utilisationRate[index].y / entries.y * 100
    })
  }
  return [labels, data]
}

export const formatIndividualPoolChartData = (start: any, end: any, volumeData: any, liquidityData: any, tokennPrice: any) => {
  const startDate = start;
  const endDate = end;
  const labels: any[] = [];
  const dates: any[] = [];
  const data: any = {
    volume: [],
    liquidity: [],
    utility: []
  }
  const from = moment(moment(startDate).format('YYYY-MM-DDT00:00:00+00')).unix().toString()
  const until = moment(moment(endDate).format('YYYY-MM-DDT00:00:00+00')).unix().toString()

  let iter = from;
  let daysOffset = 0;
  //Get all the dates for chart data
  while (iter <= until) {
    var x = moment(startDate).add(daysOffset, 'd');
    dates.push(x)
    labels.push(moment(x).utc())
    daysOffset++
    iter = moment(startDate).add(daysOffset, 'd').unix().toString();
  }

  let volumeDataIter = volumeData.length - 1;
  let liquidityDataIter = 0;
  let volumeSoFar = 0;
  let liquiditySoFar = 0;

  if (volumeData.length > 0 && liquidityData.length > 0) {
    dates.forEach((entries: any) => {
      // daily volume
      if (volumeDataIter >= 0 && moment(volumeData[volumeDataIter].t).format('ll') === moment(entries).format('ll')) {
        volumeSoFar = volumeData[volumeDataIter].volumeValue
        volumeDataIter--;
      }
      data.volume.push({
        x: entries,
        y: volumeSoFar
      })

      // daily liquidiity
      if (liquidityDataIter < liquidityData.length && moment(liquidityData[liquidityDataIter].date).format('ll') === moment(entries).format('ll')) {
        liquiditySoFar = liquidityData[liquidityDataIter].pools[0].amountValue
        liquidityDataIter++;
      }
      data.liquidity.push({
        x: entries,
        y: liquiditySoFar
      })
      data.utility.push({
        x: entries,
        y: volumeSoFar / liquiditySoFar
      })

    })
  }
  return [labels, data]
}

export const formatPositionHistoryChartData = (dataRaw: any) => {
  const labels: any[] = [];
  const data: any = {
    pnl: [],
  }
  if (dataRaw?.length) dataRaw?.forEach((entry: any) => {
    labels.push(new Date(entry.time))
    data.pnl.push((Number(entry.realizedPnl) + Number(entry.unrealizedPnl)).toFixed(2))
    // data.index.push(entry.tokens[0].index)
  })
  return [labels, data]
}

export const formatOraclePricesChartData = (dataRaw: Insights.QueryGetOraclesPriceResponse['entries']) => {
  const labels: any[] = [];
  const data: any = {
    twap: [],
    index: []
  }
  if (dataRaw?.length) dataRaw?.forEach((entry: any) => {
    labels.push(new Date(entry.timestamp ?? entry.time))
    data.twap.push(entry.tokens[0].twap)
    data.index.push(entry.tokens[0].index)
  })
  if (data.twap[0] === 0) delete data.twap
  return [labels, data]
}

export const formatOracleResponsivenessChartData = (dataRaw: any) => {
  const labels: any[] = [];
  const data: any = {
    indexPriceInState: [],
    indexPriceByOracle: [],
    binancePrice: []
  }
  if (dataRaw?.length > 0) {
    dataRaw?.forEach((entry: any) => {
      labels.push(moment(entry?.timestamp))
      data.indexPriceInState.push(entry?.state_price)
      data.indexPriceByOracle.push(entry?.oracle_price)
      data.binancePrice.push(entry?.binance_price)

      const lenOfStateArr = data.indexPriceInState.length
      const lenOfOracleArr = data.indexPriceByOracle.length

      if (lenOfStateArr > 1 && !data.indexPriceInState[lenOfStateArr - 1]) data.indexPriceInState[lenOfStateArr - 1] = data.indexPriceInState[lenOfStateArr - 2]
      if (lenOfOracleArr > 1 && !data.indexPriceByOracle[lenOfOracleArr - 1]) data.indexPriceByOracle[lenOfOracleArr - 1] = data.indexPriceByOracle[lenOfOracleArr - 2]
    })
  }
  return [labels, data]
}

export const formatAlliancesStakeChartData = (rawData: Insights.AlliancesStake[]) => {
  const labels: Moment[] = [];
  const denomMap: {
    [denom: string]: {
      totalStaked: number[]
      totalStakedValue: number[],
    }
  } = {}
  const paddedTotalStakedValue: string[] = []

  let prevDate: Moment;
  rawData.forEach((entry, index) => {
    const date = moment(entry.date).utc()
    if (!prevDate) {
      prevDate = date
    }
    while (date.diff(prevDate, "days") > 1) {
      Object.keys(denomMap).forEach((denom) => {
        const lastStakedValue = denomMap[denom].totalStakedValue.slice(-1)[0]
        const lastStakedAmount = denomMap[denom].totalStaked.slice(-1)[0]
        denomMap[denom].totalStaked.push(lastStakedAmount)
        denomMap[denom].totalStakedValue.push(lastStakedValue)
      })
      paddedTotalStakedValue.push(paddedTotalStakedValue.slice(-1)[0])
      prevDate = prevDate.clone().add(1, "days")
      labels.push(prevDate)
    }

    paddedTotalStakedValue.push(entry.totalStakedValue)
    labels.push(date)
    const stakedDenoms: string[] = []

    entry.tokens.forEach(token => {
      stakedDenoms.push(token.denom)

      if (!(token.denom in denomMap)) {
        denomMap[token.denom] = {
          totalStaked: [],
          totalStakedValue: []
        }
        const lastStakedAmount = token.totalStaked - token.staked
        const lastStakedValue = token.totalStakedValue - token.stakedValue
        for (let i = 0; i < index; i++) {
          denomMap[token.denom].totalStaked.push(lastStakedAmount)
          denomMap[token.denom].totalStakedValue.push(lastStakedValue)
        }
      }
      denomMap[token.denom].totalStaked.push(token.totalStaked)
      denomMap[token.denom].totalStakedValue.push(token.totalStakedValue)
    })

    Object.keys(denomMap)
      .filter(denom => !stakedDenoms.includes(denom))
      .forEach(denom => {
        const lastStakedValue = denomMap[denom].totalStakedValue.slice(-1)[0]
        const lastStakedAmount = denomMap[denom].totalStaked.slice(-1)[0]
        denomMap[denom].totalStaked.push(lastStakedAmount)
        denomMap[denom].totalStakedValue.push(lastStakedValue)
      })

    prevDate = date
  })

  const denomDataset = Object.keys(denomMap).map(denom => {
    const dataset = labels.map((date, index) => {
      return {
        x: date,
        y: denomMap[denom].totalStakedValue[index],
        totalStaked: denomMap[denom].totalStaked[index]
      }
    })
    return {
      denom,
      dataset
    }
  })

  return { labels, data: { dataset: denomDataset, paddedTotalStakedValue } }
}

export const formatAlliancesRewardsChartData = (rawData: Insights.AlliancesRewards[]) => {
  const labels: Moment[] = [];
  const denomMap: {
    [denom: string]: {
      totalRewards: number[],
      totalRewardsValue: number[],
    }
  } = {}

  rawData.forEach((entry, index) => {
    const date = moment(entry.date).utc()
    labels.push(date)

    const rewardedDenoms: string[] = []

    entry.tokens.forEach(token => {
      rewardedDenoms.push(token.denom)

      if (!(token.denom in denomMap)) {
        denomMap[token.denom] = {
          totalRewards: [],
          totalRewardsValue: [],
        }
        const lastRewardsAmount = token.totalRewards - token.rewards
        const lastRewardsValue = token.totalRewardsValue - token.rewardsValue
        for (let i = 0; i < index; i++) {
          denomMap[token.denom].totalRewards.push(lastRewardsAmount)
          denomMap[token.denom].totalRewardsValue.push(lastRewardsValue)
        }
      }
      denomMap[token.denom].totalRewards.push(token.totalRewards)
      denomMap[token.denom].totalRewardsValue.push(token.totalRewardsValue)
      Object.keys(denomMap)
        .filter(denom => !rewardedDenoms.includes(denom))
        .forEach(denom => {
          const lastRewardsAmount = denomMap[denom].totalRewards.slice(-1)[0]
          const lastRewardsValue = denomMap[denom].totalRewardsValue.slice(-1)[0]
          denomMap[denom].totalRewards.push(lastRewardsAmount)
          denomMap[denom].totalRewardsValue.push(lastRewardsValue)
        })
    })
  })

  const denomDatasets = Object.keys(denomMap)
    .map(denom => {
      const dataset = labels.map((date, index) => {
        return {
          x: date,
          y: denomMap[denom].totalRewardsValue[index],
          totalRewards: denomMap[denom].totalRewards[index],
        }
      })
      return {
        denom,
        dataset
      }
    })
    .sort((prev, curr) => prev.dataset.slice(-1)[0].y - curr.dataset.slice(-1)[0].y)

  const otherDenomsDataset: {
    denom: string,
    dataset: {
      x: Moment,
      y: number,
      totalRewards: number,
    }[]
  } = {
    denom: 'Others',
    dataset: []
  }

  labels.forEach((date, index) => {
    otherDenomsDataset.dataset.push({
      x: date,
      y: denomDatasets.slice(0, -10).reduce((sum, curr) => sum + curr.dataset[index].y, 0),
      totalRewards: denomDatasets.slice(0, -10).reduce(((sum, curr) => sum + curr.dataset[index].totalRewards), 0)
    })
  })

  const data = [
    ...denomDatasets.slice(-9),
    otherDenomsDataset
  ]
  return { labels, data }
}
